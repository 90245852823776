// Material Dashboard 2 React layouts
import Dashboard from "./layouts/dashboard";
// import Tables from "layouts/tables";
// / import Order from "layouts/order";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Tables from "./layouts/tables";
import Order from "./layouts/order";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Companies",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Order",
    key: "order",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/order/:id",
    component: <Order />,
  },
];

export default routes;
