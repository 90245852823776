/**
 * @fileoverview gRPC-Web generated client stub for management
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.management = require('./api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.management.AccountManagementClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.management.AccountManagementPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.management.CashBalanceRequest,
 *   !proto.management.CashBalanceResponse>}
 */
const methodDescriptor_AccountManagement_GetCashBalance = new grpc.web.MethodDescriptor(
  '/management.AccountManagement/GetCashBalance',
  grpc.web.MethodType.UNARY,
  proto.management.CashBalanceRequest,
  proto.management.CashBalanceResponse,
  /**
   * @param {!proto.management.CashBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.management.CashBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.management.CashBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.management.CashBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.management.CashBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.management.AccountManagementClient.prototype.getCashBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/management.AccountManagement/GetCashBalance',
      request,
      metadata || {},
      methodDescriptor_AccountManagement_GetCashBalance,
      callback);
};


/**
 * @param {!proto.management.CashBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.management.CashBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.management.AccountManagementPromiseClient.prototype.getCashBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/management.AccountManagement/GetCashBalance',
      request,
      metadata || {},
      methodDescriptor_AccountManagement_GetCashBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.management.ContractBalanceRequest,
 *   !proto.management.ContractBalanceResponse>}
 */
const methodDescriptor_AccountManagement_GetContractBalance = new grpc.web.MethodDescriptor(
  '/management.AccountManagement/GetContractBalance',
  grpc.web.MethodType.UNARY,
  proto.management.ContractBalanceRequest,
  proto.management.ContractBalanceResponse,
  /**
   * @param {!proto.management.ContractBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.management.ContractBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.management.ContractBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.management.ContractBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.management.ContractBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.management.AccountManagementClient.prototype.getContractBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/management.AccountManagement/GetContractBalance',
      request,
      metadata || {},
      methodDescriptor_AccountManagement_GetContractBalance,
      callback);
};


/**
 * @param {!proto.management.ContractBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.management.ContractBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.management.AccountManagementPromiseClient.prototype.getContractBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/management.AccountManagement/GetContractBalance',
      request,
      metadata || {},
      methodDescriptor_AccountManagement_GetContractBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.management.CreateUserRequest,
 *   !proto.management.CreateUserResponse>}
 */
const methodDescriptor_AccountManagement_CreateUser = new grpc.web.MethodDescriptor(
  '/management.AccountManagement/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.management.CreateUserRequest,
  proto.management.CreateUserResponse,
  /**
   * @param {!proto.management.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.management.CreateUserResponse.deserializeBinary
);


/**
 * @param {!proto.management.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.management.CreateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.management.CreateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.management.AccountManagementClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/management.AccountManagement/CreateUser',
      request,
      metadata || {},
      methodDescriptor_AccountManagement_CreateUser,
      callback);
};


/**
 * @param {!proto.management.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.management.CreateUserResponse>}
 *     Promise that resolves to the response
 */
proto.management.AccountManagementPromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/management.AccountManagement/CreateUser',
      request,
      metadata || {},
      methodDescriptor_AccountManagement_CreateUser);
};


module.exports = proto.management;

