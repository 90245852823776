import Card from "@mui/material/Card";
import * as React from 'react';
import MDBox from "components/MDBox";
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Contract from "layouts/order/components/Contract"
import {AccordionDetails, AccordionSummary} from "@mui/material";
import boxShadow from "../../../../assets/theme/functions/boxShadow";
import MDTypography from "../../../../components/MDTypography";
import {styled} from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {useEffect} from "react";

function ContractForm({expanded, setExpanded, contracts, side, setSide, price, setPrice, contractValue, setContractValue, type, setType }) {

  const handleContractChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const AccordionSummary = styled((props) => (
      <MuiAccordionSummary
          expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '0.9rem' }} />}
          {...props}
      />
  ))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 1)'
            : 'rgba(255, 255, 255, 1)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
  }));


  function Display({contract}) {
    if(expanded !== contract.contractDescription){
      let hitValue = Math.floor(Math.random() * (95 - 10 + 1) + 10);
      let yesValue = hitValue.toPrecision(2)
      let noValue = (100-hitValue).toPrecision(2)
      return <MDBox alignItems="right" display="flex" sx={{float: "right", paddingRight: "10px", paddingLeft: "200px"}}>
        <MDBox alignItems="center" fontWeight="medium" mr={1} sx={{backgroundColor: "#BCFFA5", borderRadius:1, paddingLeft: "30px", paddingRight: "30px", paddingBottom: "5px", paddingTop:"5px"}}>
          <MDTypography variant="caption" fontWeight="regular" >
            <text style={{fontSize: 20, fontWeight: "bold", color: "#23B26D"}}>0.{yesValue} </text>
          </MDTypography>
        </MDBox>
        <MDBox fontWeight="medium" mr={1} sx={{backgroundColor: 'rgba(232, 35, 35, 0.20)', borderRadius:1, paddingLeft: "30px", paddingRight: "30px", paddingBottom: "5px", paddingTop:"5px"}}>
          <MDTypography variant="caption" fontWeight="regular">
            <text style={{fontSize: 20, fontWeight: "bold", color: "#DC3737"}}>0.{noValue} </text>
          </MDTypography>
        </MDBox>
      </MDBox>
    } else if(contract?.estimate !== ""){
      return <MDBox alignItems="right" display="flex" sx={{float: "right", paddingRight: "10px", paddingLeft: "100px"}}>
          <MDTypography variant="caption" fontWeight="regular" >
            <text style={{fontSize: "15px", fontWeight: "bold", color: "#000000"}}> {contract.estimate}&nbsp;Est.</text>
          </MDTypography>
      </MDBox>
    }
      return null
  }

  return (
      <Card sx={{width: 750, backgroundColor: '#F5F7FB', boxShadow: 0, borderRadius: 3}}>
          {
              contracts?.map(contract => (
                  <MDBox sx={{width: "60w"}}>
                    <Accordion expanded={expanded === contract.contractDescription} onChange={handleContractChange(contract.contractDescription)} style={{marginTop: "25px", boxShadow: "none", borderRadius: "5"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox display="flex" alignItems="center" justifyContent="space-between" sx={{lineHeight: "10px", maxWidth: "400px", ...(expanded===contract.contractDescription && {
                          maxWidth: "fullWidth"
                          })}}>
                          <MDBox ml={3}>
                            <MDTypography variant="button" fontWeight="medium">
                              <text style={{ color: 'black', fontSize: '15px', fontWeight: "bold" }}> {contract.contractDescription} </text>
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox display="flex" justifyContent="flex-end">
                          <Display contract={contract}/>
                        </MDBox>
                      </MDBox>
                    </AccordionSummary>
                    <AccordionDetails style={{display:"flex", alignItems: "center", justifyContent: "space-between"}}>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        <Contract contract={contract} side={side} setSide={setSide} price={price} setPrice={setPrice} contractValue={contractValue} setContractValue={setContractValue}/>
                      </MDBox>
                    </AccordionDetails>
                    </Accordion>
                  </MDBox>
              ))
          }
      </Card>
      );
}


export default ContractForm;
