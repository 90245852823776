import Card from "@mui/material/Card";
import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  FormControl,
  Divider,
  Button,
  Select,
} from "@mui/material";
import { TextField } from "@mui/material";
import { createTheme } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import TeslaIcon from 'assets/images/tsla.jpeg'
import SpotIcon from 'assets/images/spot.png'
import DisneyIcon from 'assets/images/disney.jpg'
import NvidiaIcon from 'assets/images/nvidia.png'
import AmazonIcon from 'assets/images/amazon.jpeg'
import GoogleIcon from 'assets/images/google.png'
import AppleIcon from 'assets/images/apple.png'
import MstrIcon from 'assets/images/mstr.png'
import UberIcon from 'assets/images/uber.png'
import MrnaIcon from 'assets/images/mrna.png'
import MenuItem from "@mui/material/MenuItem";
import BabaIcon from "../../../../assets/images/baba.jpeg";
import AmcIcon from "../../../../assets/images/amc.png";
import TsmcIcon from "../../../../assets/images/tsmc.png";
import SpceIcon from "../../../../assets/images/spce.png";
import CostIcon from "../../../../assets/images/cost.png";
import CvxIcon from "../../../../assets/images/cvx.png";
import GmeIcon from "../../../../assets/images/gme.png";
import MetaIcon from "../../../../assets/images/meta.png";
import CvnaIcon from "../../../../assets/images/CVNA.png";
import Switch from "@mui/material/Switch";
import {styled, ThemeProvider} from "@mui/material/styles";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';



const switchTheme = createTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#23B26D"
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#E82323"
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.7,
        backgroundColor: "#23B26D",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#E82323"
        }
      }
    }
  }
});
function OrderForm( {side, setSide, expanded, setExpanded, price, setPrice, ticker, contracts, setContractValue, contractValue} ) {
  const [quantity, setQuantity] = React.useState(0)
  const [limit, setLimit] = React.useState(false)
  const [orderReady, setOrderReady] = React.useState(true)

  const OrderSideSwitch = styled(Switch)(({ theme }) => ({
    width: 65,
    height: 32,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(30px)',
        '& .MuiSwitch-thumb:before': {
        },
        '& + .MuiSwitch-track': {
          opacity: 0.5,
          backgroundColor: orderSide === 'Buy' ? '#23B26D' : '#E82323',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: orderSide === 'Buy' ? '#23B26D' : '#E82323',
      width: 30,
      height: 30,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 0.5,
      backgroundColor: orderSide === 'Buy' ? '#23B26D' : '#E82323',
      borderRadius: 20 / 2,
    },
  }));


  const icons = {
    "TSLA": TeslaIcon,
    "SPOT": SpotIcon,
    "DIS": DisneyIcon,
    "NVDA": NvidiaIcon,
    "AMZN": AmazonIcon,
    "GOOG": GoogleIcon,
    "AAPL": AppleIcon,
    "MRNA": MrnaIcon,
    "UBER": UberIcon,
    "MSTR": MstrIcon,
    "BABA": BabaIcon,
    "AMC": AmcIcon,
    "TSMC": TsmcIcon,
    "SPCE": SpceIcon,
    "COST": CostIcon,
    "CVX": CvxIcon,
    "GME": GmeIcon,
    "META": MetaIcon,
    "CVNA": CvnaIcon,
  }

  const handleSideChange = React.useCallback(event => {
    setSide(event.target.value)
  }, [setSide])

  const [checked, setChecked] = React.useState(false);
  const [orderSide, setOrderSide] = React.useState("Buy")
  const [limitPrice, setLimitPrice] = React.useState(price)

  const handleOrderSideChange = (event) => {
    setChecked(event.target.checked);
    if(orderSide === "Buy") {
      setOrderSide("Sell")
    } else {
      setOrderSide("Buy")
    }
  };

  const handleContractValueChange = React.useCallback(event => {
    setContractValue(event.target.value)
  },[setContractValue])

  const handlePriceChange = React.useCallback(event => {
    setPrice(event.target.value)
    setLimitPrice(event.target.value)
  }, [setPrice])

  const handleQuantityChange = React.useCallback(event => {
    setQuantity(event.target.value)
  },[setQuantity])

  const buyingPower = 3864

  const handleMarket = (e) => {
    e.preventDefault();
    setLimit(false)
  }

  const handleLimit = (e) => {
    e.preventDefault();
    setLimit(true)
  }

  const handleYes = (e) => {
    e.preventDefault();
    setSide("yes")
  }

  const handleNo = (e) => {
    e.preventDefault();
    setSide("no")
  }

  const sides = ["Yes", "No"]

  let ctrs = []
  for(let i=0; i<contracts?.length; i++) {
    ctrs.push(
        contracts[i].contractDescription
    )
  }

  const clicked = {
    backgroundColor: "#2338B2",
    fontColor: "#FFFFFF",
    boxShadow: 6,
  }

  const unclicked = {
    backgroundColor: "#E7EAFA",
    fontColor: "#2338B2",
    boxShadow: 0,
  }

  const buttonTheme = createTheme({
    palette: {
      primary: {
        main: "#8c98e5", ...(orderReady && {
          main: clicked.backgroundColor
        }),
        contrastText: "#ffffff"
      },
    }
  });

  const OrderTypeButton = () => {
    let marketColor = clicked
    let limitColor = unclicked
    if(limit) {
      marketColor = unclicked
      limitColor = clicked
    }
    return <MDBox alignItems="center" display="flex" mr={3}>
      <a href="#" onClick={handleMarket}>
        <MDBox alignItems="center" fontWeight="medium" mr={3} ml={10} sx={{backgroundColor: marketColor.backgroundColor, borderRadius:3, boxShadow: marketColor.boxShadow, width: "90px", height: "37px"}}>
          <text style={{fontSize: 12, paddingLeft: "8px", fontWeight: "bold", color: marketColor.fontColor}}>Market Order </text>
        </MDBox>
      </a>
      <a href="#" onClick={handleLimit}>
        <MDBox alignItems="center" display="flex" fontWeight="medium" sx={{backgroundColor: limitColor.backgroundColor, borderRadius:3, boxShadow: limitColor.boxShadow, width: "90px", height: "37px"}}>
          <text style={{fontSize: 12, paddingLeft: "12px", fontWeight: "bold", color: limitColor.fontColor}}> Limit Order </text>
        </MDBox>
      </a>
    </MDBox>
  }

  const SelectFormSection = ({title, menuArray}) => {
    const handleChange = (event) => {
      setContractValue(event.target.value);
      setExpanded(event.target.value ? expanded : false)
    };

    let forms = []
    forms.push(<MenuItem value={""}>{title}</MenuItem>)
    for(let i=0; i<menuArray.length; i++) {
      forms.push(<MenuItem value={menuArray[i]}>{menuArray[i]}</MenuItem>)
    }

    return (
        <MDBox display="flex" alignItems="center" justifyContent="space-between" mt={3}>
          <text style={{fontSize: 18, fontWeight: "bold", marginRight: "90px"}}>{title}:</text>
          <FormControl sx={{width: "180px", height: "35px"}} variant="outlined" label="Fix">
            <Select
                style={{height: "50px", backgroundColor: "#E7EAFA"}}
                value={contractValue}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            >
              {forms}
            </Select>
          </FormControl>
        </MDBox>
    );
  }

  const LimitPriceForm = () => {
    if(limit) {
      return <MDBox display="flex" alignItems="center" justifyContent="space-between" mt={3} ml={2} sx={{height: "35px"}}>
        <text style={{fontSize: 18, fontWeight: "bold", marginTop: "10px", marginRight: "50px"}}>Limit Price:</text>
        <MDBox
            component="ul"
            display="flex"
            flexDirection="column"
            mr={2}
            sx={{
              backgroundColor: '#E7EAFA',
              width: "60px",
              borderRadius: 2
            }}
        >
          <TextField size="small" variant="outlined" value={limitPrice} onChange={handlePriceChange}/>
        </MDBox>
      </MDBox>
    }
    return null
  }

  const ContractSideSetUp = () => {
    const unclickedYes = {
      backgroundColor: "#BCFFA5",
      fontColor: "#23B26D",
      boxShadow: 0,
    }
    const clickedYes = {
      backgroundColor: "#23B26D",
      fontColor: "#FFFFFF",
      boxShadow: 6,
    }
    const unclickedNo = {
      backgroundColor: 'rgba(232, 35, 35, 0.20)',
      fontColor: "#E82323",
      boxShadow: 0,
    }

    const clickedNo = {
      backgroundColor: "#E82323",
      fontColor: "#FFFFFF",
      boxShadow: 6,
    }


    let yesValue = unclickedYes
    let noValue = unclickedNo
    if(side==='yes') {
      yesValue = clickedYes
      noValue = unclickedNo
    } else if(side==='no') {
      yesValue = unclickedYes
      noValue = clickedNo
    }

    return <MDBox display="flex" alignItems="center" justifyContent="space-between" mt={3}>
      <text style={{fontSize: 18, fontWeight: "bold", marginRight: "90px"}}>Side:</text>
      <MDBox alignItems="left" display="flex">
        <a href="#" onClick={handleYes}>
          <MDBox alignItems="center" fontWeight="medium" mr={1} ml={1} sx={{backgroundColor: yesValue.backgroundColor, borderRadius:3, boxShadow: yesValue.boxShadow, width: "72px", height: "37px"}}>
            <text style={{fontSize: 12, paddingLeft: "25px", fontWeight: "bold", color: yesValue.fontColor}}>Yes</text>
          </MDBox>
        </a>
        <a href="#" onClick={handleNo}>
          <MDBox alignItems="center" display="flex" fontWeight="medium" sx={{backgroundColor: noValue.backgroundColor, borderRadius:3, boxShadow: noValue.boxShadow, width: "72px", height: "37px"}}>
            <text style={{fontSize: 12, paddingLeft: "25px", fontWeight: "bold", color: noValue.fontColor}}>No</text>
          </MDBox>
        </a>
      </MDBox>
    </MDBox>
  }

  const Summary = ({field, value}) => {
    return <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={0}
        mx = {3}
        my={2}
        sx={{ listStyle: "none" }}
    >
      <MDBox display="flex" alignItems="center" justifyContent="space-between" mt={1} sx={{height: "10px"}}>
        <text style={{fontSize: 18, fontWeight: "bold", marginTop: "10px", marginRight: "50px", flex: "flex-end"}}>{field}:</text>
        <MDBox
            component="ul"
            display="flex"
            flexDirection="column"
            sx={{
              backgroundColor: '#FFFFFF',
            }}
        >
          <text style={{fontSize: 18, fontWeight: "bold", marginTop: "10px", marginLeft: "15px"}}>${value}</text>
        </MDBox>
      </MDBox>
    </MDBox>
  }
  
  return (
    <Card sx={{ position: "fixed", backgroundColor:'#FFFFFF', boxShadow: 0, borderRadius: 2, borderTop: 4, borderColor: '#23B26D', ...(orderSide === 'Sell' && {
        borderColor: '#E82323',
      })}}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          <MDBox>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            <Avatar>
              <img src={icons[ticker]} width={'100%'} height={'100%'} backgroundColor={'#012240'}/>
            </Avatar>
            <h1>&nbsp; {ticker}</h1>
            <MDBox ml={10} sx={{display: 'flex', alignItems: 'center'}}>
              <ThemeProvider theme={switchTheme}>
                <OrderSideSwitch onChange={handleOrderSideChange} checked={checked}/>
              </ThemeProvider>
              <text style={{fontSize: 30, color: '#23B26D', paddingLeft: 5, ...(orderSide === 'Sell' && {
                  color: '#E82323'
                })}}> {orderSide}</text>
            </MDBox>
          </div>
          </MDBox>
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2}>
        <OrderTypeButton/>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          m={2}
        >
          <SelectFormSection title={"Contract"} menuArray={ctrs}/>
          <ContractSideSetUp/>
        </MDBox>
        <MDBox display="flex" alignItems="center" justifyContent="space-between" mt={3} ml={2} sx={{height: "20px"}}>
          <text style={{fontSize: 18, fontWeight: "bold", marginTop: "10px", marginRight: "50px", flex: "flex-end"}}>Quantity:</text>
        <MDBox
            component="ul"
            display="flex"
            flexDirection="column"
            mr={2}
            my={3}
            sx={{
              backgroundColor: '#E7EAFA',
              width: "60px",
              borderRadius: 2
            }}
        >
          <TextField size="small" onChange={handleQuantityChange}/>
        </MDBox>
        </MDBox>
        <LimitPriceForm/>
        <MDBox display="flex" alignItems="center" justifyContent="space-between" mt={3}>
          <MDBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            mx = {3}
            my={2}
            sx={{ listStyle: "none" }}
          >
            <h5>Total Buying Power:  ${buyingPower.toFixed(2)} </h5>
          </MDBox>
        </MDBox>

        <Divider/>

        <Summary field={"Contract Total"} value={(price*quantity).toFixed(2)}/>
        <Summary field={"Commission"} value={(price*quantity*0.05).toFixed(2)}/>

      </MDBox>
        <Button
            theme={buttonTheme}
            x={{height: 50, marginTop: 7, boxShadow: 0}}
            variant="contained">
          Place Order: ${(price*quantity*1.05).toFixed(2)}
        </Button>
    </Card>
  );
}

export default OrderForm;
