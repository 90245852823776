import * as React from 'react';
import MDBox from 'components/MDBox';
import AccountMenu from 'components/AccountMenu'

const drawerWidth = 240;

export default function HeaderNav() {
    return (
            <MDBox sx={{backgroundColor: "#F5F7FB", height: "50px", overflow: "hidden", marginLeft: "150px"}}>
                <MDBox mt={2} sx={{marginLeft: "1250px", display:"flex", alignItems: "right"}}>
                    <AccountMenu />
                </MDBox>
            </MDBox>

    );
}