// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/dashboard/components/Transaction";

function Positions() {

  return (
    <Card sx={{ height: "100%", backgroundColor: "#F5F7FB", boxShadow: 0}}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2} mb={-1}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          Recommended 
        </MDTypography>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <Transaction
            name="DIS"
            iconVal="DisneyIcon"
            contract1="PROFIT"
            contract2="DISNEY+ SUBSCRIBERS"
          />
          <Transaction
            name="GOOG"
            iconVal="GoogleIcon"
            contract1="REVENUE"
            contract2="PIXEL 12 LAUNCH"
          />
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <Transaction
            name="NVDA"
            iconVal="NvidiaIcon"
            contract1="CAR LAUNCH"
            contract2="GPU PROFIT"
          />
          <Transaction
            name="TSLA"
            iconVal="TeslaIcon"
            contract1="PROFIT"
            contract2="PRODUCTION"
          />
          <Transaction
            name="AMZN"
            iconVal="AmazonIcon"
            contract1="PROFIT"
            contract2="AWS REVENUE"
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Positions;
