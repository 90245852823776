// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TeslaIcon from 'assets/images/tsla.jpeg'
import SpotIcon from 'assets/images/spot.png'
import DisneyIcon from 'assets/images/disney.jpg'
import NvidiaIcon from 'assets/images/nvidia.png'
import AmazonIcon from 'assets/images/amazon.jpeg'
import GoogleIcon from 'assets/images/google.png'

function Watch({ name, description, hitValue, missValue }) {

  const icons = {
    "TeslaIcon": TeslaIcon,
    "SpotifyIcon": SpotIcon,
    "DisneyIcon": DisneyIcon,
    "NvidiaIcon": NvidiaIcon,
    "AmazonIcon": AmazonIcon,
    "GoogleIcon": GoogleIcon,
  }

  return (
    <MDBox key={name} component="li" py={1} pr={2} mb={1} sx={{borderRadius: 2, backgroundColor: "#FFFFFF"}}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
        <MDBox mr={2} ml={3} display="flex" flexDirection="column" alignItems="center">
          <MDBox mb={-2}>
            <text style={{fontSize: 35, fontWeight: "bold", color: "#2338B2"}}>{hitValue}</text>
          </MDBox>
          <MDBox alignItems="center">
            <text style={{fontSize: 14, fontWeight: "bold", color: "#2338B2"}}>{missValue}</text>
          </MDBox>
        </MDBox>
          <MDBox display="flex" flexDirection="column" ml={1}>
          <text style={{fontSize: 14, fontWeight: "medium", color: "#808080"}}>{name} </text>
            <MDTypography variant="caption" fontWeight="regular">
              <text style={{fontSize: 15, fontWeight: "bolder", color: "#000000"}}>{description}</text>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDTypography variant="button" fontWeight="medium">

        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props of the Transaction
Watch.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Watch;
