/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Watch from "layouts/dashboard/components/Watch";
import Transaction from "layouts/dashboard/components/Transaction";

function Watchlist() {
  return (
    <Card sx={{ height: "100%", backgroundColor:"#F5F7FB", boxShadow:0}}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          Upcoming Events
        </MDTypography>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <Watch
            name="Spotify"
            description="Q2 Earnings Call"
            hitValue="20"
            missValue="AUG"
          />
          <Watch
            name="Amazon"
            description="Q2 Earnings Call"
            hitValue="27"
            missValue="AUG"
            iconVal="AmazonIcon"
          />
          <Watch
            name="Apple"
            description="2022 iPhone Event"
            hitValue="13"
            missValue="SEP"
            iconVal="GoogleIcon"
          />
          <Watch
            name="Nvidia"
            description="Developers Conference"
            hitValue="18"
            missValue="SEP"
            iconVal="NvidiaIcon"
          />
          <Watch
            name="Google"
            description="2022 Financial Outlook Announcement"
            hitValue="2"
            missValue="OCT"
            iconVal="GoogleIcon"
          />
        </MDBox>
        </MDBox>
    </Card>
  );
}

export default Watchlist;
