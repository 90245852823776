// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {
    CashBalanceRequest,
} from "../../protos/management/api_pb";

import { AccountManagementClient } from "../../protos/management/api_grpc_web_pb";
import {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";

let balanceClient = new AccountManagementClient("http://localhost:9081", {}, {})

function Summary() {
    const [balance, setBalance] = useState(null)
    useEffect(() => {
        let req = new CashBalanceRequest()
        req.setUserId("c6e1f5bd-2113-4527-ac79-6a1fc06d3d05")
        console.log(req)
        balanceClient.getCashBalance(req, {}, (err, response) => {
            if (err) {
                console.log({
                    message: "Order Service",
                    description: err.toString(),
                    response: response.toString(),
                });
            } else {
                setBalance(response.toObject().balance);
                console.log({
                    message: "Order Service",
                    description: "CreateOrder completed",
                    duration: 1,
                    response: response.toString(),
                });
            }
        });
    }, []);

  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        data: [33, 25, 35, 51, 54, 76],
        fill: true,
        backgroundColor: "#E7EAFA",
        borderColor: "#2338B2",
      }
    ]
  };
  const options= {
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      point:{
          radius: 0
      }
  }
    }
  return (
    <Card sx={{backgroundColor: '#F5F7FB', boxShadow:0}}>
      <MDBox pt={3} mb={2}>
        <MDTypography variant="h4" fontWeight="medium">
          Portfolio
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} sx={{backgroundColor: '#FFFFFF', borderRadius: 2}}>
      <MDBox pt={3}>
        <text style={{fontWeight: "bold", fontSize: "40px", color:"#2338B2"}}> 
          $10,385.82
        </text>
      </MDBox>
      <MDBox pt={3} mb={2} mt={-5} ml={1}>
        <text style={{fontSize: "16px", color:"#808080", fontWeight: "medium"}}> 
          Available cash: ${balance}
        </text>
      </MDBox>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        <div classpoin="chart-area">
                  <Line data={data} options={options}
                  />
              </div>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Summary;
