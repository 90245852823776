import React, { useEffect } from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Configurator from "examples/Configurator";
import theme from "assets/theme";
import routes from "routes";

import { useMaterialUIController } from "context";
import SideNav from "layouts/sidenav";
import HeaderNav from "layouts/headernav";
import BouncingDotsLoader from "./loader";
import {useAuth0} from "@auth0/auth0-react";

export default function App() {
  const [controller] = useMaterialUIController();
  const {
    layout,
  } = controller;

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const { isLoading, loginWithRedirect, isAuthenticated, user } = useAuth0();

  useEffect(() => {
      async function login() {
          await loginWithRedirect()
      }

      if(!isLoading && !isAuthenticated) login();
  }, [isLoading]);

    // const isAuthenticated = true;


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {(layout === "dashboard" &&isAuthenticated) && (
        <>
           <SideNav />
          <HeaderNav/>
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {isAuthenticated ? getRoutes(routes) : null}
        <Route path="*" element={isAuthenticated ? <Navigate to="/dashboard" /> : <BouncingDotsLoader/>} />
        <Route path="/dashboard" element={isAuthenticated ? <Navigate to="/dashboard" /> : <BouncingDotsLoader/>} />
        <Route path="/order/:id" element={isAuthenticated ? <Navigate to="/order:id" /> : <BouncingDotsLoader/>} />
      </Routes>
    </ThemeProvider>
  );
}
