// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TeslaIcon from 'assets/images/tsla.jpeg'
import SpotIcon from 'assets/images/spot.png'
import DisneyIcon from 'assets/images/disney.jpg'
import NvidiaIcon from 'assets/images/nvidia.png'
import AmazonIcon from 'assets/images/amazon.jpeg'
import GoogleIcon from 'assets/images/google.png'
import MrnaIcon from 'assets/images/mrna.png'
import AppleIcon from 'assets/images/apple.png'
import {FaLongArrowAltUp} from "react-icons/fa"


function NewsFeed({news}) {
    
  const icons = {
    "TeslaIcon": TeslaIcon,
    "SpotifyIcon": SpotIcon,
    "DisneyIcon": DisneyIcon,
    "NvidiaIcon": NvidiaIcon,
    "AmazonIcon": AmazonIcon,
    "GoogleIcon": GoogleIcon,
    "AppleIcon": AppleIcon,
    "MrnaIcon": MrnaIcon,
  }
  var companies = [
    {
      Name: "Moderna",
      Tick : "MRNA",
      Contract1: "FDA APPROVAL",
      Contract2: "BANKRUPTCY",
      Icon: "MrnaIcon",
      Color: "#E82323",
      Position: "$3737.65",
      Gain: "37"
    },
    {
      Name: "Apple",
      Tick : "AAPL",
      Contract1: "AIRPODS LAUNCH",
      Contract2: "APPLE MUSIC SUBSCRIBERS",
      Icon: "AppleIcon",
      Color: "#E82323",
      Position: "1232.98",
      Gain: "5"
    },
    {
      Name: "Disney",
      Tick : "DIS",
      Contract1: "DISNEY+ SUBSCRIBERS",
      Contract2: "REVENUE",
      Icon: "DisneyIcon",
      Color: "#23B26D",
      Position: "$896.38",
      Gain: "16"
    },
    {
      Name: "Google",
      Tick : "GOOG",
      Contract1: "PIXEL LAUNCH",
      Contract2: "GC USERS",
      Icon: "GoogleIcon",
      Color: "#23B26D",
      Position: "$745.45",
      Gain: "22"
    },
    {
      Name: "Amazon",
      Tick : "AMZN",
      Contract1: "AWS PROFIT",
      Contract2: "REVENUE",
      Icon: "AmazonIcon",
      Color: "#E82323",
      Position: "$692.71",
      Gain: "8"
    },
    {
      Name: "Nvidia",
      Tick : "NVDA",
      Contract1: "GPU PROFIT",
      Contract2: "SELF-DRIVING LAUNCH",
      Icon: "NvidiaIcon",
      Color: "#E82323",
      Position: "$254.32",
      Gain: "14"
    },
    {
      Name: "Spotify",
      Tick : "SPOT",
      Contract1: "SUBSCRIBERS",
      Contract2: "PROFIT%",
      Icon: "SpotifyIcon",
      Color: "#23B26D",
      Position: "$2343.45",
      Gain: "17"
    },
  ]

  var rows = []
  for(var i=0; i<companies.length; i++) {
    rows.push(
    <MDBox mt={0.5} py={1} pr={2} mb={1} sx={{borderRadius: 2, backgroundColor: "#FFFFFF"}}>
    <MDBox display="flex" alignItems="center">
    <MDBox display="flex" alignItems="center" justifyContent="space-between">
      <MDBox mr={1} ml={2} alignItems="center" justifyContent="space-between" display="flex">
        <img src={icons[companies[i].Icon]} width={'40px'} height={'40px'} backgroundColor={'#012240'}/>
      </MDBox>
      <MDBox display="flex" flexDirection="column" ml={1}>
      <text style={{fontSize: 15, fontWeight: "bolder", color: "#000000"}}>{companies[i].Name}</text>
      <text style={{fontSize: 13, fontWeight: "medium", color: "#808080"}}>{companies[i].Tick}</text>
      </MDBox>
    </MDBox>
    <MDBox alignItems="right" justifyContent="space-between" display="flex">
    <MDBox fontWeight="medium" ml={3} mr={1} sx={{backgroundColor: "#E7EAFA", borderRadius:1, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "2px"}}>
    <MDTypography variant="caption" fontWeight="regular">
          <text style={{fontSize: 12, fontWeight: "bold", color: "#2338B2"}}>{companies[i].Contract1} </text>
        </MDTypography>
    </MDBox>
    </MDBox>
    <MDBox alignItems="flex-end" justifyContent="flex-end" display="flex" flexDirection="column" style={{position: "absolute", right: "25px"}}>
    <text style={{fontSize: 15, fontWeight: "bolder", color: "#000000"}}>{companies[i].Position}</text>
      <MDBox mr={1} ml={2} alignItems="center" justifyContent="space-between" display="flex">
      <FaLongArrowAltUp style={{color: "#23B26D", fontSize: "0.8em"}}></FaLongArrowAltUp>
      <text style={{fontSize: 14, fontWeight: "bolder", color: "#23B26D"}}>{companies[i].Gain}%</text>
      </MDBox>
    </MDBox>
    </MDBox>
    </MDBox>)
}

  return (

    <Card sx={{backgroundColor:"#F5F7FB", boxShadow:0}}>
      <MDBox pt={3} mb={2}>
      <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          Top Positions
        </MDTypography>
      </MDBox>
        {rows}
    </Card>
  );
}

export default NewsFeed;
