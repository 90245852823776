// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/dashboard/components/Transaction";
import React from "react";
import {FaLongArrowAltUp} from "react-icons/fa";
import TeslaIcon from "../../../../assets/images/tsla.jpeg";
import SpotIcon from "../../../../assets/images/spot.png";
import DisneyIcon from "../../../../assets/images/disney.jpg";
import NvidiaIcon from "../../../../assets/images/nvidia.png";
import AmazonIcon from "../../../../assets/images/amazon.jpeg";
import GoogleIcon from "../../../../assets/images/google.png";
import AppleIcon from "../../../../assets/images/apple.png";
import contract from "../../../order/components/Contract";

function Movers() {
    const icons = {
        "TeslaIcon": TeslaIcon,
        "SpotifyIcon": SpotIcon,
        "DisneyIcon": DisneyIcon,
        "NvidiaIcon": NvidiaIcon,
        "AmazonIcon": AmazonIcon,
        "GoogleIcon": GoogleIcon,
        "AppleIcon": AppleIcon,
    }

    const companies = [
        {
            Name: "Spotify",
            Tick : "SPOT",
            Contract: "Subscribers",
            Movement: "23%",
            Icon: "SpotifyIcon",
            Color: "#23B26D"
        },
        {
            Name: "Apple",
            Tick : "APPL",
            Contract: "Airpods Launch",
            Movement: "18%",
            Icon: "AppleIcon",
            Color: "#E82323"
        },
        {
            Name: "Disney",
            Tick : "DIS",
            Contract: "Disney+ Subscribers",
            Movement: "14%",
            Icon: "DisneyIcon",
            Color: "#23B26D"
        },
        {
            Name: "Google",
            Tick : "GOOG",
            Contract: "Revenue",
            Movement: "12%",
            Icon: "GoogleIcon",
            Color: "#23B26D"
        },
        {
            Name: "Amazon",
            Tick : "AMZN",
            Contract: "AWS PROFIT",
            Movement: "11%",
            Icon: "AmazonIcon",
            Color: "#E82323"
        },
    ]
    var rows = []
    for(var i=0; i<5; i++) {
        rows.push(
            <MDBox mt={0.5} py={1} pr={2} mb={1} sx={{borderRadius: 2, backgroundColor: "#FFFFFF"}}>
                <MDBox display="flex" alignItems="center">
                    <MDBox display="flex" alignItems="center" justifyContent="space-between">
                        <MDBox mr={1} ml={2} alignItems="center" justifyContent="space-between" display="flex">
                            <img src={icons[companies[i].Icon]} width={'40px'} height={'40px'} backgroundColor={'#012240'}/>
                        </MDBox>
                        <MDBox display="flex" flexDirection="column" ml={1}>
                            <text style={{fontSize: 15, fontWeight: "bolder", color: "#000000"}}>{companies[i].Name}</text>
                            <text style={{fontSize: 13, fontWeight: "medium", color: "#808080"}}>{companies[i].Tick}</text>
                        </MDBox>
                    </MDBox>
                    <MDBox alignItems="right" justifyContent="space-between" display="flex">
                        <MDBox fontWeight="medium" ml={3} mr={1} sx={{
                            backgroundColor: "#E7EAFA",
                            borderRadius: 1,
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingBottom: "2px"
                        }}>
                            <MDTypography variant="caption" fontWeight="regular">
                                <text style={{fontSize: 12, fontWeight: "bold", color: "#2338B2"}}>{companies[i].Contract}</text>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox alignItems="flex-end" justifyContent="flex-end" display="flex" flexDirection="column"
                           style={{position: "absolute", right: "25px"}}>
                        <MDBox mr={1} ml={2} alignItems="center" justifyContent="space-between" display="flex">
                            <FaLongArrowAltUp style={{color: companies[i].Color, fontSize: "0.8em"}}></FaLongArrowAltUp>
                            <text style={{fontSize: 14, fontWeight: "bolder", color: companies[i].Color}}>{companies[i].Movement}</text>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>)
    }

    return (
        <Card sx={{ height: "100%", backgroundColor: "#F5F7FB", boxShadow: 0}}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2} mb={-1}>
            </MDBox>
            <MDBox pt={3} pb={2} px={2} sx={{backgroundColor:'#F5F7FB'}}>
                <MDBox pb={2} px={2} sx={{height: '70px',  backgroundColor:'#FFFFFF', borderRadius: 2}} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize" mt={2}>
                        <text style={{fontSize: 25, fontWeight: "me", color: "#2338B2"}}>Recent Orders</text>
                    </MDTypography>
                </MDBox>
                <MDTypography variant="h4" fontWeight="medium" alignItems="center" textTransform="capitalize" mt={3}>
                    Top Movers
                </MDTypography>
                {rows}
            </MDBox>
        </Card>
    );
}

export default Movers;
