
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


// Explore component x
import Movers from "layouts/tables/components/movers";
import Watchlist from "layouts/tables/components/Watchlist"
import Companies from "layouts/tables/components/Companies"
import React, { useState, useEffect } from "react";
import MDTypography from "../../components/MDTypography";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function Dashboard() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <DashboardLayout>
            <MDBox>
                <MDBox>
                    <Grid container spacing={2}>
                        <Grid item xs={8} lg={8}>
                            <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                                Companies
                            </MDTypography>
                            <MDBox sx={{height: '1200px', overflowY: 'scroll', backgroundColor:'#F5F7FB'}}>
                                <Companies/>
                            </MDBox>
                        </Grid>
                        <Grid item xs={2} lg={4}>
                            <MDBox>
                                <Movers/>
                            </MDBox>
                            <MDBox>
                                <Watchlist/>
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;