/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Watch from "layouts/dashboard/components/Watch";
import Transaction from "layouts/dashboard/components/Transaction";

function Watchlist() {
    return (
        <Card sx={{ height: "100%", backgroundColor:"#F5F7FB", boxShadow:0}}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                    Watchlist
                </MDTypography>
            </MDBox>
            <MDBox pt={3} pb={2} px={2}>
                <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                >
                    <MDBox py={1} pr={2} mb={1} sx={{borderRadius: 2, backgroundColor: "#FFFFFF"}}>
                        <MDBox display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox display="flex" alignItems="center">
                                <MDBox ml={3} display="flex" flexDirection="column" alignItems="center">
                                    <MDBox alignItems="center" display={"flex"} flexDirection={"row"}>
                                        <text style={{fontSize: 30, fontWeight: "bold", color: "#2338B2"}}>{"+"}</text>
                                        <text style={{fontSize: 15, marginLeft:5, marginTop:3, fontWeight: "bold", color: "#2338B2"}}>{"ADD CONTRACTS TO WATCHLIST"} </text>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                            <MDTypography variant="button" fontWeight="medium">

                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default Watchlist;
