// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import TeslaIcon from 'assets/images/tsla.jpeg'
import SpotIcon from 'assets/images/spot.png'
import DisneyIcon from 'assets/images/disney.jpg'
import NvidiaIcon from 'assets/images/nvidia.png'
import AmazonIcon from 'assets/images/amazon.jpeg'
import GoogleIcon from 'assets/images/google.png'
import AppleIcon from 'assets/images/apple.png'
import MrnaIcon from 'assets/images/mrna.png'
import UberIcon from 'assets/images/uber.png'
import MstrIcon from 'assets/images/mstr.png'
import BabaIcon from 'assets/images/baba.jpeg'
import AmcIcon from 'assets/images/amc.png'
import TsmcIcon from 'assets/images/tsmc.png'
import SpceIcon from 'assets/images/spce.png'
import CostIcon from 'assets/images/cost.png'
import CvxIcon from 'assets/images/cvx.png'
import GmeIcon from 'assets/images/gme.png'
import MetaIcon from 'assets/images/meta.png'
import CvnaIcon from 'assets/images/CVNA.png'


import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {ContractHistoricalClient} from "layouts/tables/protos/management/contract_historical_grpc_web_pb";
import {GetEntitiesRequest} from "layouts/tables/protos/management/contract_historical_pb";


let historicalClient = new ContractHistoricalClient("http://ec2-3-15-157-155.us-east-2.compute.amazonaws.com:8081", {}, {})

function Companies() {

    const [companies, setCompanies] = useState(null)
    useEffect(() => {
        let req = new GetEntitiesRequest()
        console.log(req)
        historicalClient.getEntities(req, {}, (err, response) => {
            if (err) {
                console.log({
                    message: "Contract Historical Service",
                    description: err.toString(),
                });
            } else {
                setCompanies(response.toObject().entitiesList);
                console.log({
                    message: "Contract Historical Service",
                    description: "GetEntities completed",
                    duration: 1,
                    response: response.toObject(),
                });
            }
        });
    }, []);

    const icons = {
        "TSLA": TeslaIcon,
        "SPOT": SpotIcon,
        "DIS": DisneyIcon,
        "NVDA": NvidiaIcon,
        "AMZN": AmazonIcon,
        "GOOG": GoogleIcon,
        "AAPL": AppleIcon,
        "MRNA": MrnaIcon,
        "UBER": UberIcon,
        "MSTR": MstrIcon,
        "BABA": BabaIcon,
        "AMC": AmcIcon,
        "TSMC": TsmcIcon,
        "SPCE": SpceIcon,
        "COST": CostIcon,
        "CVX": CvxIcon,
        "GME": GmeIcon,
        "META": MetaIcon,
        "CVNA": CvnaIcon,
    }


    return (
        <Card sx={{backgroundColor:'#F5F7FB', boxShadow:0}}>
            <MDBox pt={3} mb={2}>
            </MDBox>
            {companies?.map(company => (<Link to={'/order/' + company.entityId}>
                <MDBox py={1} pr={2} mb={1} sx={{borderRadius: 2, backgroundColor: "#FFFFFF"}}>
                    <MDBox display="flex" alignItems="center">
                        <MDBox display="flex" alignItems="center" justifyContent="space-between">
                            <MDBox mr={1} ml={2} alignItems="center" justifyContent="space-between" display="flex">
                                <img src={icons[company.entityId]} width={'40px'} height={'40px'}
                                     backgroundColor={'#012240'}/>
                            </MDBox>
                            <MDBox display="flex" flexDirection="column" ml={1}>
                                <text style={{
                                    fontSize: 15,
                                    fontWeight: "bolder",
                                    color: "#000000"
                                }}>{company.companyName}</text>
                                <text style={{
                                    fontSize: 13,
                                    fontWeight: "medium",
                                    color: "#808080"
                                }}>{company.entityId}</text>
                            </MDBox>
                        </MDBox>
                        <MDBox alignItems="right" justifyContent="space-between" display="flex">
                            <MDBox fontWeight="medium" ml={3} mr={1} sx={{
                                backgroundColor: "#E7EAFA",
                                borderRadius: 1,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingBottom: "2px"
                            }}>
                                {/*<MDTypography variant="caption" fontWeight="regular">*/}
                                {/*    <text style={{*/}
                                {/*        fontSize: 12,*/}
                                {/*        fontWeight: "bold",*/}
                                {/*        color: "#2338B2"*/}
                                {/*    }}>{companies[i].Contract1} </text>*/}
                                {/*</MDTypography>*/}
                            </MDBox>
                            <MDBox fontWeight="medium" mr={1} sx={{
                                backgroundColor: "#E7EAFA",
                                borderRadius: 1,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingBottom: "2px"
                            }}>
                                {/*<MDTypography variant="caption" fontWeight="regular">*/}
                                {/*    <text style={{*/}
                                {/*        fontSize: 12,*/}
                                {/*        fontWeight: "bold",*/}
                                {/*        color: "#2338B2"*/}
                                {/*    }}>{companies[i].Contract2} </text>*/}
                                {/*</MDTypography>*/}
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Link>)

            )}
        </Card>
    );
}

export default Companies;
