import MDBox from "components/MDBox";
import React, {useCallback, useEffect, useRef} from 'react'
import CheckIcon from "@material-ui/icons/Check";
import {Divider, ToggleButton, ToggleButtonGroup} from "@mui/material";

import { Line } from "react-chartjs-2"
import MDTypography from "../../../../components/MDTypography";
import Avatar from "@mui/material/Avatar";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {makeStyles} from "@mui/styles";
import {Chart} from "chart.js";


  const data = {
    labels: ["Nov 24th", "Nov 27th", "Nov 30th", "Dec 2nd", ""],
    datasets: [
      {
        label: "yes",
        data: [0.55, 0.38, 0.51, 0.62, 0.76],
        borderColor: "#00A624",
        lineTension: 0.3,
      },
      {
        label: "no",
        data: [0.45, 0.62, 0.49, 0.38, 0.24],
        borderColor: "#D00000",
          lineTension: 0.3,
      }
    ]
  };
  const options= {
      animation: {
          duration: 0
      },
      scales: {
          x: {
              grid: {
                  display: false,
              }
          },
          y: {
              grid: {
                  drawBorder: false
              }
          }
      },
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      point:{
          radius: 0
      },
    }
  }

const useCheckedStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: "#2338B2",
        border: `3px solid ${'#2338B2'}`,
        color: theme.palette.info.main
    }
}));

const useUnCheckedStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: "#FFFFFF",
        border: `3px solid ${'#2338B2'}`,
        color: theme.palette.info.main
    }
}));


function Contract({expanded, setExpanded, contract, side, setSide, price, setPrice, contractValue, setContractValue}) {
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

    const [marketPrice, setMarketPrice] = React.useState(() => {
        let yesValue = 76
        let noValue = 24
        return [yesValue, noValue]
   })


  const [alignment, setAlignment] = React.useState('');
  const [contractSide, setContractSide] = React.useState(['', ''])


  const checkedClass = useCheckedStyles();
  const unCheckedClass = useUnCheckedStyles();


  const handlePrice = useCallback(value => {
      setPrice(value)
  }, [setPrice])

  const handleSide = useCallback(value => {
    setSide(value);
  }, [setSide])

  const handleContractValue = useCallback(value => {
    setContractValue(value);
  }, [setContractValue])


  const handleClick = (valValue, sideValue, price) => {
    if(side === sideValue) {
      handleContractValue(null);
      handleSide(null);
      setContractSide(['', ''])
      handlePrice(0)

    } else {
      handleContractValue(valValue);
      handleSide(sideValue);
      setContractSide([valValue, sideValue])
        handlePrice(price)
    }
    console.log(contractSide)
  };

    return(
        <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={0}
        mx={3}
        my={3}
        sx={{alignItems: "center"}}
    >
    <ToggleButtonGroup
      value={alignment}
      exclusive
      aria-label="text alignment"
      sx={{borderRadius: 5, marginBottom: "35px", width: "700px", paddingLeft: "20px", paddingRight: "50px"}}
    >
      <ToggleButton value='yes'
        sx={{
          backgroundColor: '#BCFFA5', ...((side === 'yes' && contractValue === contract.contractDescription) && {
            backgroundColor: '#23B26D', boxShadow: 12
          }),
          borderRadius: 2,
          marginRight: "20px",
          "&:hover": {
            backgroundColor: "#62FE2B",
            boxShadow: 15
          },
          width: "100%",
          height: "60px"
        }}
        onClick={() => handleClick(contract.contractDescription, 'yes', marketPrice[0]/100)}
      > 
        <text style={{ fontWeight: "medium", color: '#00A624', ...((side === 'yes' && contractValue === contract.contractDescription) && {
            color: '#FFFFFF'
          }), fontSize:15}}>Buy Yes:&nbsp;</text>
        <text style={{ color: '#00A624', fontWeight: "bold", ...((side === 'yes' && contractValue === contract.contractDescription) && {
            color: '#FFFFFF'
          }), fontSize:15}}>$0.{marketPrice[0]}</text>
      </ToggleButton>`
      <ToggleButton value='no'
        alignment="no"
        sx={{
          backgroundColor: 'rgba(232, 35, 35, 0.20)', boxShadow: 0, ...((side === 'no' && contractValue === contract.contractDescription) && {
            backgroundColor: '#DC3737',
                boxShadow: 12
          }),
          borderRadius: 2,

          "&:hover": {
            backgroundColor: "#FF5757",
            boxShadow: 15
          },
          width: "100%",
          height: "60px"
        }}
        onClick={() => handleClick(contract.contractDescription, 'no', marketPrice[1]/100) }
      >
         <text style={{ fontWeight: "medium", color: '#D00000', ...((side === 'no' && contractValue === contract.contractDescription) && {
            color: '#FFFFFF'
          }), fontSize:15}}>Buy No:&nbsp;</text>
        <text style={{ color: '#D00000', fontWeight: "bold", ...((side === 'no' && contractValue === contract.contractDescription) && {
            color: '#FFFFFF'
          }), fontSize:15}}>$0.{marketPrice[1]}</text>
      </ToggleButton>
    </ToggleButtonGroup>
    <MDBox sx={{width: "700px", alignItems: "left"}}>
     <div classpoin="chart-area" style={{width: "650px", paddingLeft: "20px"}}>
          <Line data={data} options={options} />
      </div>
        </MDBox>
    {/*<MDBox mr={30}>*/}
    {/*  <MDBox mt={5}>*/}
    {/*    <text style={{ fontWeight: "bold", color: "#00A624", fontSize: "13px"}}> — YES,</text>*/}
    {/*    <text style={{ color: "#000000", fontSize: "13px"}}> &nbsp;{contract.contractDescription.slice(0, -1)}</text>*/}
    {/*  </MDBox>*/}
    {/*  <MDBox>*/}
    {/*    <text style={{ fontWeight: "bold", color: "#D00000", fontSize: "13px"}}> — NO,</text>*/}
    {/*    <text style={{ color: "#000000", fontSize: "13px"}}> &nbsp;{contract.contractDescription.slice(0, -1)}</text>*/}
    {/*    <text style={{ color: "#000000", fontSize: "13px", marginLeft: "1px"}}> Return per Contract: </text>*/}
    {/*  </MDBox>*/}
    {/*</MDBox>*/}
        <MDBox sx={{width: "575px"}}>
            <Divider/>
            <MDTypography align='center'>
                <text style={{ fontWeight: "bold", color: "#000000", fontSize: "15px"}}> Contract Settlement Terms:</text>
                <text style={{ color: "#000000", fontSize: "15px"}}>&nbsp;Contract is settled by revenue figures from Apple's Q4 2022 Earnings report published in Q1 2023. </text>
            </MDTypography>
            <MDBox ml={1} mt={2}>
                <MDTypography>
                    <a href={'https://investor.apple.com/investor-relations/'}>
                        <text style={{ fontWeight: "bold", color: "#000000", fontSize: "15px"}}> Contract Resolution Oracle:</text>
                        <text style={{ fontWeight: "bold", color: "#2338B2", fontSize: "15px", display: "inline"}}>&nbsp;https://investor.apple.com/investor-relations/</text>
                    </a>
                </MDTypography>
            </MDBox>
            <MDBox display="flex" ml={3}>
                <MDBox mt={5}>
                    <MDBox>
                        <Avatar className={checkedClass.avatar}>
                            <CheckIcon style={{color: "#FFFFFF"}}/>
                        </Avatar>
                    </MDBox>
                    <MDBox ml={-5}>
                        <MDTypography align='center'>
                            <text style={{fontSize: "13px", fontWeight: "bold", color: "#2338B2"}}>November 24th, 2022</text>
                        </MDTypography>
                        <MDTypography align='center' mt={-2}>
                            <text style={{fontSize: "13px", fontWeight: "bold", color: "#2338B2"}}>Contract Opens</text>
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox mt={5}>
                    <TrendingFlatIcon fontSize="large" sx={{color:"#2338B2"}}/>
                </MDBox>
                <MDBox mt={5}>
                    <MDBox ml={5}>
                        <Avatar className={unCheckedClass.avatar}>
                            <CheckIcon style={{color: "#FFFFFF"}}/>
                        </Avatar>
                    </MDBox>
                    <MDBox>
                        <MDTypography align='center'>
                            <text style={{fontSize: "13px", fontWeight: "bold", color: "#2338B2"}}>January 20th, 2022</text>
                        </MDTypography>
                        <MDTypography align='center' mt={-2}>
                            <text style={{fontSize: "13px", fontWeight: "bold", color: "#2338B2"}}>Contract Closes</text>
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox mt={5}>
                    <TrendingFlatIcon fontSize="large" sx={{color:"#2338B2"}}/>
                </MDBox>
                <MDBox mt={5}>
                    <MDBox ml={5}>
                        <Avatar className={unCheckedClass.avatar}>
                            <CheckIcon style={{color: "#FFFFFF"}}/>
                        </Avatar>
                    </MDBox>
                    <MDBox>
                        <MDTypography align='center'>
                            <text style={{fontSize: "13px", fontWeight: "bold", color: "#2338B2"}}>January 31st, 2023</text>
                        </MDTypography>
                        <MDTypography align='center' mt={-2}>
                            <text style={{fontSize: "13px", fontWeight: "bold", color: "#2338B2"}}>Contract is Verified</text>
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox mt={5}>
                    <TrendingFlatIcon fontSize="large" sx={{color:"#2338B2"}}/>
                </MDBox>
                <MDBox mt={5}>
                    <MDBox ml={5}>
                        <Avatar className={unCheckedClass.avatar}>
                            <CheckIcon style={{color: "#FFFFFF"}}/>
                        </Avatar>
                    </MDBox>
                    <MDBox>
                        <MDTypography align='center' ml={1}>
                            <text style={{fontSize: "13px", fontWeight: "bold", color: "#2338B2"}}>Contract is Paid</text>
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </MDBox>
    </MDBox>
    )
}

export default Contract;