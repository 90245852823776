import * as React from 'react';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Avatar } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import MDTypography from 'components/MDTypography';
import { display } from '@mui/system';
import LoginButton from "../../components/buttons/login-button";
import {LogoutButton} from "../../components/buttons/logout-button";
import App from "../../App";
import {useAuth0} from "@auth0/auth0-react";

const drawerWidth = 240;


export default function SideNav() {
  return (
    <div style={{float: "left", backgroundColor: "#051680", height: "100vh", width: "225px", position: "fixed", display: "max-content"}}>
        <MDBox mt={"3vh"} ml={"4vh"}>
        <text style={{fontWeight: "bold", fontSize: "50px", color:"#FFFFFF"}}> 
            DelFi 
          </text>
          </MDBox>

        <MDBox mt={7}>
        <Link to={'/dashboard'}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft: "4vh"}}>
            <DashboardIcon style={{color: "#ffffff", height: 30, width: 30}}/>
            <text style={{color: "#e6e6e6"}}>&nbsp;&nbsp;Dashboard</text>
         </div>  
        </Link>
        </MDBox>

        <MDBox mt={3}>
        <Link to={'/tables'}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft: "4vh"}}>
            <BarChartIcon style={{color: "#ffffff", height: 30, width: 30}}/>
            <text style={{color: "#e6e6e6"}}>&nbsp;&nbsp;Explore</text>
         </div>  
        </Link>
        </MDBox>
      </div>
        
  );
}