// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar"; 


// Dashboard component x
import OrderForm from "layouts/order/components/OrderForm"
import ContractForm from "layouts/order/components/ContractForm"
import TeslaIcon from 'assets/images/tsla.jpeg'
import SpotIcon from 'assets/images/spot.png'
import DisneyIcon from 'assets/images/disney.jpg'
import NvidiaIcon from 'assets/images/nvidia.png'
import AmazonIcon from 'assets/images/amazon.jpeg'
import GoogleIcon from 'assets/images/google.png'

import React from "react";
import { useParams } from 'react-router';
import { useEffect } from "react";
import {EntityContractsRequest} from "../tables/protos/management/contract_historical_pb";
import {ContractHistoricalClient} from "../tables/protos/management/contract_historical_grpc_web_pb";

let historicalClient = new ContractHistoricalClient("httpe://3.15.157.155:8081", {}, {})

function Order() {
  const [side, setSide] = React.useState('')
  const [contractValue, setContractValue] = React.useState(0)
  const [price, setPrice] = React.useState(0)
  const [type, setType] = React.useState()
  const [calculated, setCalculated] = React.useState(false)
  const { id } = useParams();

  const [contracts, setContracts] = React.useState(null)
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    let req = new EntityContractsRequest()
    req.setEntityId(id)
    console.log(req)
    historicalClient.getContractsForEntity(req, {}, (err, response) => {
      if (err) {
        console.log({
          message: "Contract Historical Service",
          description: err.toString(),
        });
      } else {
        setContracts(response.toObject().contractsList);
        console.log({
          message: "Contract Historical Service",
          description: "GetContractsForEntity completed",
          duration: 1,
          response: response.toObject(),
        });
      }
    });
  }, []);

  return (
    <DashboardLayout>
        <Grid container spacing={0.5}>
          <Grid mt={8}>
            {<ContractForm ticker={id} expanded={expanded} setExpanded={setExpanded} contracts={contracts} side={side} setSide={setSide} price={price} setPrice={setPrice} contractValue={contractValue} setContractValue={setContractValue} type={type} setType={setType}/>}
          </Grid>
          <Grid item xs={2} lg={4} mt={9.4}>
            <MDBox sx={{ width: 350, height: 650}} mx={3} my={5} borderRadius={"none"}>
                { <OrderForm
                  ticker={id}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  contracts={contracts}
                  side={side} 
                  setSide={setSide}
                  price={price}
                  setPrice={setPrice}
                  setContractValue={setContractValue} 
                  contractValue={contractValue}
                  type={type}
                  setType={setType}
                />}
            </MDBox>
          </Grid>
        </Grid>
    </DashboardLayout>
  );
}

export default Order;
