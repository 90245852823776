
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import TeslaIcon from 'assets/images/tsla.jpeg'
import SpotIcon from 'assets/images/spot.png'
import DisneyIcon from 'assets/images/disney.jpg'
import NvidiaIcon from 'assets/images/nvidia.png'
import AmazonIcon from 'assets/images/amazon.jpeg'
import GoogleIcon from 'assets/images/google.png'
import { Avatar } from "@mui/material";

function Transaction({ name, iconVal, contract1, contract2}) {
  
  const icons = {
    "TeslaIcon": TeslaIcon,
    "SpotifyIcon": SpotIcon,
    "DisneyIcon": DisneyIcon,
    "NvidiaIcon": NvidiaIcon,
    "AmazonIcon": AmazonIcon,
    "GoogleIcon": GoogleIcon,
  }

  return (
    <MDBox key={name} component="li" py={1.25} pr={2} mb={1} sx={{borderRadius: 2, backgroundColor: "#ffffff"}}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center" justifyContent="space-between">
          <MDBox mr={1} ml={2} alignItems="center" justifyContent="space-between" display="flex">
            <img src={icons[iconVal]} width={'30px'} height={'30px'} backgroundColor={'#012240'}/>
          </MDBox>
          <MDBox>
            <MDTypography variant="button" fontWeight="medium">
              {name}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox alignItems="right" justifyContent="space-between" display="flex">
        <MDBox fontWeight="medium" mr={1} sx={{backgroundColor: "#E7EAFA", borderRadius:1, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "0.25vh"}}>
        <MDTypography variant="caption" fontWeight="regular">
              <text style={{fontSize: 12, fontWeight: "bold", color: "#2338B2"}}>{contract1} </text>
            </MDTypography>
        </MDBox>
        <MDBox fontWeight="medium" mr={1} sx={{backgroundColor: "#E7EAFA", borderRadius:1, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "2px"}}>
        <MDTypography variant="caption" fontWeight="regular">
              <text style={{fontSize: 12, fontWeight: "bold", color: "#2338B2"}}>{contract2} </text>
            </MDTypography>
        </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Transaction;
