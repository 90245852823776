
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


// Dashboard component x
import Positions from "layouts/dashboard/components/Positions";
import Summary from "layouts/dashboard/components/Summary";
import Watchlist from "layouts/dashboard/components/Watchlist"


import NewsFeed from "layouts/dashboard/components/News";
import React, { useEffect } from "react";
import LoginButton from "../../components/buttons/login-button";


function createData(
  name,
  calories,
  fat,
  carbs,
  protein,
) {
  return { name, calories, fat, carbs, protein };
}

function Dashboard() {
  // const [feed, setFeed] = React.useState(null)
  //
  // useEffect(() => {
  //   getNewsFeed();
  //
  //   async function getNewsFeed() {
  //     console.log("Fetching ...")
  //     const response = await fetch("/newsfeed");
  //     const data = await response.json();
  //     console.log(data)
  //     setFeed(data)
  //   }
  // }, [setFeed]);

  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={8} lg={8}>
              <Summary/>
                <MDBox>
                  <NewsFeed></NewsFeed>
                </MDBox>
            </Grid>
            <Grid item xs={2} lg={4}>
              <MDBox>
                  <Positions/>
              </MDBox>
              <MDBox>
                  <Watchlist/>
              </MDBox>
            </Grid>  
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
