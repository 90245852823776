import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, useNavigate} from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import {Auth0ProviderWithHistory} from "./auth0-provider-with-history";
import {Auth0Provider} from "@auth0/auth0-react";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
        <Auth0Provider
            domain="dev-h2jh8j27ajmx52tx.us.auth0.com"
            clientId="yoKQPt0M7QLoeH0QFESJt9hREJVsCn74"
            redirectUri="https://www.dev-trydelfi-dfhq.net/dashboard"
        >
            <App />
        </Auth0Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
